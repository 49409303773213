import { createContext } from "preact";
import { useEffect, useState, useCallback } from "preact/hooks";
import { route } from 'preact-router';
import { fetchInvoice } from "../../api/get_invoice";
import { NetworkToCurrency } from "../../helpers/currency-helpers";

export const InvoiceContext = createContext({});

export const InvoiceProvider = ({ children }) => {
  const [invoice, setInvoice] = useState({
    store: {
      id: '',
      store_name: '',
      currency: '',
      amount: 0,
      status: 'NEW',
      received_amount: 0,
      remaining_amount: 0,
      userPaidBy: {
        currency: {},
        network: {}
      },
      transactions: [],
      success_redirect_url: ''
    },
    currencyList: [],
    currency: null,
    network: null,
    timeLeft: 0,
    loading: true,
    updating: false,
    initialized: false,
    status: 'NEW'
  });

  const setStore = useCallback(
    (store) => {
      setInvoice(prev => ({
        ...prev,
        store,
      }));
    },
    [setInvoice],
  );

  const setCurrencyList = useCallback(
    (currencyList) => {
      setInvoice(prev => ({
        ...prev,
        currencyList,
      }));
    },
    [setInvoice],
  );

  const setCurrency = useCallback(
    ({ currency, network }) => {
      setInvoice(prev => ({
        ...prev,
        currency,
        network
      }));
    },
    [setInvoice],
  );

  const setTimeLeft = useCallback(
    (timeLeft) => {
      setInvoice(prev => ({
        ...prev,
        timeLeft,
      }));
    },
    [setInvoice],
  );

  const setLoading = useCallback(
    (loading) => {
      setInvoice(prev => ({
        ...prev,
        loading,
      }));
    },
    [setInvoice],
  );

  const setUpdating = useCallback(
    (updating) => {
      setInvoice(prev => ({
        ...prev,
        updating,
      }));
    },
    [setInvoice],
  );

  const setStatus = useCallback(
    (status) => {
      setInvoice(prev => ({
        ...prev,
        status,
      }));
    },
    [setInvoice],
  );

  const getInvoiceData = useCallback(
    (invoiceId, currencyId, networkId) => {
      fetchInvoice(
        invoiceId,
        currencyId,
        networkId,
        invoice,
        setLoading,
        setUpdating,
        setStore,
        setCurrencyList,
        setTimeLeft,
        selectCurrency,
        clearCurrency,
        statusRedirect
      );
    },
    [invoice, fetchInvoice],
  );

  const clearCurrency = () => {
    route(`/${invoice.store.id}`);
    return setCurrency({ currency: null, network: null })
  };

  const selectCurrency = (currency, network, silent) => {
    if (!silent) {
      if (network) {
        route(`/${invoice.store.id}?currency=${currency.currency}&network=${NetworkToCurrency[network.network]}`);
      } else {
        route(`/${invoice.store.id}?currency=${currency.currency}`);
      }
    }

    return setCurrency({ currency, network: network ?? invoice.network })
  };

  const selectNetwork = (network) => {
    route(`/${invoice.store.id}?currency=${invoice.currency.currency}&network=${NetworkToCurrency[network.network]}`);

    return setCurrency({ currency: invoice.currency, network: network })
  };

  const initInvoice = (invoiceId, currencyId, networkId, status) => {
    if (!invoice.initialized) {
      setInvoice(prev => ({
        ...prev,
        initialized: true,
        status
      }));

      return getInvoiceData(invoiceId, currencyId, networkId)
    }
  };

  const statusRedirect = (storeId, storeStatus, storeRemainingAmount, storeLoading) => {
    if (storeStatus !== invoice.status) {
      setStatus(storeStatus);

      if (storeStatus === 'PENDING') {
        if (!storeRemainingAmount) {
          route(`/${storeId}/pending`);
        } else {
          route(`/${storeId}/unpaid`);
        }
      }

      if (storeStatus === 'PAID') {
        route(`/${storeId}/success`);
      }

      if (!storeStatus && !storeLoading) {
        route('');
      }
    }
  };

  if ((invoice.store.status === "PENDING" || invoice.store.status === "NEW") && invoice.store.id) {
    useEffect(() => {
      const timer = setInterval(() => getInvoiceData(invoice.store.id, null, null), 2000);

      return () => clearInterval(timer);
    }, []);
  }

  if ((invoice.store.status === "PENDING" || invoice.store.status === "NEW") && invoice.store.id && invoice.timeLeft !== 0) {
    useEffect(() => {
      const timer = invoice.timeLeft > 0 && setInterval(() => setTimeLeft(invoice.timeLeft - 1), 1000);

      if (invoice.timeLeft <= 0) {
        getInvoiceData(invoice.store.id, null, null);
      }

      return () => clearInterval(timer);
    }, [invoice.timeLeft]);
  }

  return (
    <InvoiceContext.Provider
      value={{
        invoice,
        initInvoice,
        selectCurrency,
        selectNetwork,
        clearCurrency,
      }}
    >
      {children}
    </InvoiceContext.Provider>
  )
};

