export const NetworkToCurrency = {
  'Bitcoin': 'BTC',
  'ERC-20': 'ETH',
  'TRC-20': 'TRX'
}

export const CurrencyToNetwork = {
  'BTC': 'Bitcoin',
  'ETH': 'ERC-20',
  'TRX': 'TRC-20'
}
