import { CP_API_ENDPOINT } from "../const/general";
import {CurrencyToNetwork, NetworkToCurrency} from "../helpers/currency-helpers";
import {svgIcons} from "../helpers/svg-helpers";
import {STORAGE_KEYS} from "../const/storage_keys";

export const fetchInvoice = async (
  invoiceId,
  currencyId,
  networkId,
  stateInvoice,
  setLoading,
  setUpdating,
  setStore,
  setCurrencyList,
  setTimeLeft,
  selectCurrency,
  clearCurrency,
  statusRedirect
) => {
  if (invoiceId && !stateInvoice.updating) {
    setUpdating(true);

    let versionHeader = null;
    const res = await fetch(
      `${CP_API_ENDPOINT}/api/v1/checkout/invoices/${invoiceId}`
    ).then((response) => {
      versionHeader = response.headers.get('X-CP-FRONTEND-VERSION');
      return response.json()
    });

    const storageVersion = localStorage.getItem(STORAGE_KEYS.VERSION) || null;
    if (versionHeader && versionHeader !== String(storageVersion)) {
      localStorage.setItem(STORAGE_KEYS.VERSION, versionHeader);
      location.reload(true);
    }

    const tempList = [
      {
        name: "Bitcoin",
        value: (Number(res.btc_amount) * 1.005).toFixed(18),
        valueField: "btc_amount",
        currency: "BTC",
        types: [
          {
            currency: "BTC",
            network: "Bitcoin",
            address: res.btc_address,
            confirmation: res.min_btc_confirmations,
            count: res.btc_confirmations_count,
            txLink: 'https://live.blockcypher.com/btc/tx/TX_HASH/'
          },
        ],
        icon: svgIcons.btc,
        usdAmount: res.usd_amount,
      },
      {
        name: "Tether USDT",
        value: res.usd_amount,
        valueField: "usd_amount",
        currency: "USDT",
        types: [
          {
            network: "ERC-20",
            currency: "USDTETH",
            address: res.usdteth_address,
            confirmation: res.min_eth_confirmations,
            count: res.usdteth_confirmations_count,
            txLink: 'https://etherscan.io/tx/TX_HASH'
          },
          {
            network: "TRC-20",
            currency: "USDTTRX",
            address: res.usdttrx_address,
            confirmation: 1,
            count: res.usdtrx_confirmations_count,
            txLink: 'https://tronscan.org/#/transaction/TX_HASH'
          },
        ],
        icon: svgIcons.tether,
        usdAmount: res.usd_amount,
      },
      {
        name: "ETH",
        value: (Number(res.eth_amount) * 1.005).toFixed(18),
        valueField: "eth_amount",
        currency: "ETH",
        types: [
          {
            network: "ERC-20",
            currency: "ETH",
            address: res.eth_address,
            confirmation: res.min_eth_confirmations,
            count: res.eth_confirmations_count,
            txLink: 'https://etherscan.io/tx/TX_HASH'
          }
        ],
        icon: svgIcons.eth,
        usdAmount: res.usd_amount,
      },
      // {
      //   name: "Pay by Card",
      //   icon: svgIcons.bankCard,
      //   link: res.stripe_checkout_url,
      //   value: res.usd_amount,
      //   currency: "USD",
      // },
    ];

    const userPaidBy = {
      currency: {},
      network: {}
    };

    if (!userPaidBy.currency.currency && res.transactions?.length) {
      const { currency, net_hash } = res.transactions[0];

      const newCurrency = tempList.find((el) =>
        el.types?.find((type) => type.currency === currency)
      );

      if (newCurrency) {
        const newNetwork = newCurrency.types?.find((el) => el.currency === currency);
        newNetwork.txLink = newNetwork.txLink.replace('TX_HASH', net_hash);

        userPaidBy.currency = newCurrency;
        userPaidBy.network = newNetwork;
      }
    }

    const newStore = {...res};
    newStore.userPaidBy = userPaidBy;
    setStore(newStore);

    const disabledCurrencies = [...(newStore?.disabled_currencies || [])];
    const newTempList = tempList.map((el) => {
      const disabledCurrency = disabledCurrencies.find((curr) => curr.currency === el.currency);

      let hasCurrency = !!disabledCurrency;

      if (hasCurrency) {
        el.types = el.types.filter((type) => !disabledCurrency.networks.includes(NetworkToCurrency[type.network]))
        if (!!el.types.length) {
          hasCurrency = false;
        }
      }

      return hasCurrency ? null : el
    }).filter((el) => !!el);
    setCurrencyList(newTempList);

    setTimeLeft(res.seconds_to_rate_updating ? res.seconds_to_rate_updating + 1 : null);

    if (currencyId) {
      const currencyTemplate = newTempList.find((currencyTemp) => currencyTemp.currency === currencyId);

      if (currencyTemplate) {
        if (currencyTemplate.types.length === 1) {
          selectCurrency(currencyTemplate, currencyTemplate.types[0], true);
        } else {
          const networkTemplate = currencyTemplate.types.find((networkTemp) => networkTemp.network === (CurrencyToNetwork[networkId] ?? ''));
          selectCurrency(currencyTemplate, networkTemplate ?? null, true);
        }
      } else {
        clearCurrency();
      }
    }

    setLoading(false);
    setUpdating(false);
    statusRedirect(newStore.id, newStore.status, Number(newStore.remaining_amount), false);
  } else {
    setLoading(false);
    setUpdating(false);
  }
};
