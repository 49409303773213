import { useContext } from "preact/hooks";
import { InvoiceContext } from "../contexts/InvoiceContext";

const useInvoice = () => {
  const invoiceContext = useContext(InvoiceContext)

  if (invoiceContext === undefined) {
    throw new Error('Invoice context undefined')
  }

  return invoiceContext
}

export default useInvoice
