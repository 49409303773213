export const CP_API_ENDPOINT = process.env.CP_API_ENDPOINT;
export const CP_API_TESTNET = Boolean(process.env.CP_API_TESTNET);

export const NETWORKS = {
  ETH: {
    MAINNET: {
      RPC: 'https://mainnet.infura.io/v3/',
      CHAIN: 1,
      NAME: 'Ethereum Mainnet',
      SYMBOL: 'ETH',
      EXPLORER: 'https://etherscan.io'
    },
    TESTNET: {
      RPC: 'https://eth-goerli.public.blastapi.io',
      CHAIN: 5,
      NAME: 'GOERLI Testnet',
      SYMBOL: 'ETH',
      EXPLORER: 'https://goerli.etherscan.io'
    }
  },
  TRX: {
    MAINNET: {
      RPC: 'https://api.trongrid.io',
      CHAIN: 0,
      NAME: '',
      SYMBOL: 'TRX',
      EXPLORER: 'https://tronscan.org'
    },
    TESTNET: {
      RPC: 'https://api.trongrid.io',
      CHAIN: 0,
      NAME: '',
      SYMBOL: 'TRX',
      EXPLORER: 'https://tronscan.org'
    }
  }
};
