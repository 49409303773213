import "./scss";
import { IntlProvider } from "preact-i18n";
import { Router } from 'preact-router';
import definition from "./en.json";
import { InvoiceProvider } from "./contexts/InvoiceContext";
import Header from "./components/header";
import Footer from "./components/footer";
import ErrorPage from "./routes/404";
import Payment from "./routes/payment";
import Pending from "./routes/pending";
import Success from "./routes/success";
import Unpaid from "./routes/unpaid";

const AppInt = () => {
  return (
    <IntlProvider definition={definition}>
      <div id="app">
        <InvoiceProvider>
          <Header />
          <Router>
            <ErrorPage path="/" />
            <Payment path="/:invoiceId" />
            <Pending path="/:invoiceId/pending" />
            <Unpaid path="/:invoiceId/unpaid" />
            <Success path="/:invoiceId/success" />
          </Router>
          <Footer />
        </InvoiceProvider>
      </div>
    </IntlProvider>
  );
};

export default AppInt;
