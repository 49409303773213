import css from "./style.scss";
import cx from "classnames";
import { Text } from "preact-i18n";
import useInvoice from "../../hooks/useInvoice";

const Header = () => {
  const { invoice } = useInvoice();

  return (
    <header class={cx(css.header, "text-center")}>
      {invoice.store.store_name ? <h1 class={css.merchant}>{invoice.store.store_name}</h1> : null}{" "}
      {invoice.store.id ? (
        <h3 class={css.order}>
          <Text id="merchant.order">Order</Text> <span>{invoice.store.id}</span>
        </h3>
      ) : null}
    </header>
  );
};

export default Header;
