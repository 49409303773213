import css from "./style.scss";
import cx from "classnames";
import { Text } from "preact-i18n";
import { svgIcons } from "../../helpers/svg-helpers";
import { MarkupText } from "preact-i18n";
const terms = "https://cryptoprocessing.io/terms-of-use";
const privacy = "https://cryptoprocessing.io/privacy-policy";
const Footer = () => (
  <footer class={cx(css.footer)}>
    <div class={cx(css.copy)}>
      © 2017 - 2023
    </div>
    <div class={cx(css.secured)}>
      <div class={cx(css.icon)}>{svgIcons.lock()}</div>
      <Text id="footer.secured">Secured by </Text>
      <b>
        &nbsp;
        <Text id="footer.crypto"> CryptoProcessing</Text>
      </b>
    </div>
    <div class={cx(css.terms)}>
      <MarkupText fields={{ terms, privacy }} id="footer.privacy" />
    </div>
  </footer>
);

export default Footer;
