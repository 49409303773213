const btc = (fill = "#f7931a") => (
  <svg
    fill="none"
    height="24"
    width="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect fill={fill} height="24" rx="4" width="24" />
    <path
      clip-rule="evenodd"
      d="m17.2923 10.2903c.2389-1.5969-.977-2.45532-2.6397-3.02797l.5394-2.16319-1.317-.32811-.5251 2.10623c-.3462-.08634-.7017-.16769-1.0551-.24834l.5289-2.12014-1.3162-.32812-.5396 2.16249c-.2865-.06522-.5679-.12968-.841-.19761l.0016-.0068-1.81613-.45346-.35033 1.40643s.97707.22394.95648.23773c.53329.13308.62973.48607.61372.76585l-.61443 2.46441c.03673.0093.08436.0228.13692.0439l-.03827-.0095c-.03249-.0082-.06626-.0166-.10093-.0249l-.86121 3.4522c-.06518.162-.2306.4052-.60345.3128.01319.0192-.95718-.2388-.95718-.2388l-.65385 1.5073 1.7138.4272c.18767.0471.37313.0954.5567.1432.12829.0335.25567.0667.38224.099l-.54497 2.188 1.31543.3282.53968-2.1648c.35938.0975.70808.1875 1.04948.2723l-.5378 2.1546 1.317.3281.5449-2.1839c2.2457.425 3.9342.2536 4.645-1.7774.5727-1.6351-.0285-2.5783-1.21-3.1934.8605-.1984 1.5087-.7643 1.6815-1.9333l-.0004-.0003zm-3.0092 4.2188c-.3734 1.5002-2.7219.88-3.7964.5962-.0967-.0255-.183-.0483-.2567-.0666l.7232-2.8987c.0897.0224.1994.047.3237.0749h.0001c1.1115.2494 3.3887.7604 3.0062 2.2942zm-2.783-3.6395c.8958.239 2.8501.7605 3.1905-.603h.0001c.3476-1.39455-1.5517-1.81499-2.4793-2.02033-.1043-.0231-.1964-.04348-.2716-.06222l-.6556 2.62895c.0619.0155.1345.0348.2159.0566z"
      fill="#fff"
      fill-rule="evenodd"
    />
  </svg>
);
const tether = (fill = "#50af95") => (
  <svg
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect fill={fill} height="24" rx="4" width="24" />
    <path
      clip-rule="evenodd"
      d="m13.5195 13.3604c-.0848.0063-.5233.0325-1.5013.0325-.7779 0-1.3302-.0234-1.5239-.0325-3.00621-.1323-5.25006-.6555-5.25006-1.282s2.24385-1.149 5.25006-1.2834v2.0442c.1966.0142.7595.0474 1.5374.0474.9334 0 1.4009-.0389 1.485-.0467v-2.0435c2.9998.1337 5.2387.6569 5.2387 1.282s-2.2382 1.1483-5.2387 1.2812zm.0004-2.7754v-1.82922h4.1865v-2.78947h-11.3982v2.78947h4.1857v1.82852c-3.40215.1563-5.9607.8301-5.9607 1.6376s2.55855 1.4807 5.9607 1.6377v5.8617h3.0253v-5.8639c3.3944-.1562 5.9487-.8294 5.9487-1.6362s-2.5521-1.4799-5.9487-1.6369z"
      fill="#fff"
      fill-rule="evenodd"
    />
  </svg>
);
const eth = (fill = "#627EEA") => (
  <svg
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect fill={fill} height="24" rx="4" width="24" />
    <path d="M20 0H4C1.79086 0 0 1.79086 0 4V20C0 22.2091 1.79086 24 4 24H20C22.2091 24 24 22.2091 24 20V4C24 1.79086 22.2091 0 20 0Z" fill="#627EEA"/>
    <path d="M11.6235 3V9.6525L17.2463 12.165L11.6235 3Z" fill="white" fill-opacity="0.602"/>
    <path d="M11.6235 3L6 12.165L11.6235 9.6525V3Z" fill="white"/>
    <path d="M11.6235 16.4759V20.9962L17.25 13.2119L11.6235 16.4759Z" fill="white" fill-opacity="0.602"/>
    <path d="M11.6235 20.9962V16.4752L6 13.2119L11.6235 20.9962Z" fill="white"/>
    <path d="M11.6235 15.4298L17.2463 12.1651L11.6235 9.65405V15.4298Z" fill="white" fill-opacity="0.2"/>
    <path d="M6 12.1651L11.6235 15.4298V9.65405L6 12.1651Z" fill="white" fill-opacity="0.602"/>
  </svg>
);
const lock = (fill = "#667085") => (
  <svg
    fill="none"
    height="16"
    viewBox="0 0 17 16"
    width="17"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m5.16667 7.33325v-2.66666c0-.88406.35119-1.73191.97631-2.35703s1.47297-.97631 2.35702-.97631c.88406 0 1.7319.35119 2.357.97631s.9763 1.47297.9763 2.35703v2.66666m-7.99997 0h9.33337c.7363 0 1.3333.59696 1.3333 1.33334v4.66671c0 .7363-.597 1.3333-1.3333 1.3333h-9.33337c-.73638 0-1.33333-.597-1.33333-1.3333v-4.66671c0-.73638.59695-1.33334 1.33333-1.33334z"
      stroke={fill}
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.33333"
    />
  </svg>
);
const info = (fill = "#344054") => (
  <svg
    fill="none"
    height="16"
    viewBox="0 0 16 16"
    width="16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m7.99967 10.6666v-2.66668m0-2.66667h.00667m6.65996 2.66667c0 3.68188-2.9847 6.66668-6.66663 6.66668-3.68189 0-6.66666-2.9848-6.66666-6.66668 0-3.6819 2.98477-6.66667 6.66666-6.66667 3.68193 0 6.66663 2.98477 6.66663 6.66667z"
      stroke={fill}
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.33333"
    />
  </svg>
);
const left = (fill = "#667085") => (
  <svg
    fill="none"
    height="20"
    viewBox="0 0 20 20"
    width="20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m15.8337 10.0001h-11.66671m0 0 5.83331 5.8333m-5.83331-5.8333 5.83331-5.83335"
      stroke={fill}
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.67"
    />
  </svg>
);
const change = (fill = "#344054") => (
  <svg
    fill="none"
    height="20"
    viewBox="0 0 20 20"
    width="20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill={fill}>
      <path d="m2.5 7.91675-.63324-.77396c-.32713.26765-.45065.71207-.30856 1.11015.1421.39807.51913.66381.9418.66381zm15 1c.5523 0 1-.44772 1-1 0-.55229-.4477-1-1-1zm-9.78343-3.97604c.42745-.34973.49045-.97976.14072-1.4072s-.97975-.49045-1.40719-.14072zm-5.21657 3.97604h15v-2h-15zm.63324-.22604 4.58333-3.75-1.26647-1.54792-4.58334 3.75z" />
      <path d="m17.5 12.0833.6332.7739c.3272-.2676.4507-.7121.3086-1.1101-.1421-.3981-.5191-.6638-.9418-.6638zm-15-1c-.55228 0-1 .4477-1 1 0 .5522.44772 1 1 1zm9.7834 3.976c-.4274.3497-.4904.9797-.1407 1.4072.3497.4274.9798.4904 1.4072.1407zm5.2166-3.976h-15v2h15zm-.6332.226-4.5834 3.75 1.2665 1.5479 4.5833-3.75z" />
    </g>
  </svg>
);
const copy = (fill = "#344054") => (
  <svg
    fill="none"
    height="20"
    viewBox="0 0 20 20"
    width="20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m4.16602 12.5001h-.83334c-.44203 0-.86595-.1756-1.17851-.4882-.31256-.3125-.48815-.7365-.48815-1.1785v-7.49999c0-.44202.17559-.86595.48815-1.17851s.73648-.48815 1.17851-.48815h7.50002c.442 0 .8659.17559 1.1785.48815s.4881.73649.4881 1.17851v.83334m-3.33328 3.33333h7.49998c.9205 0 1.6667.74619 1.6667 1.66667v7.49995c0 .9205-.7462 1.6667-1.6667 1.6667h-7.49998c-.92048 0-1.66667-.7462-1.66667-1.6667v-7.49995c0-.92048.74619-1.66667 1.66667-1.66667z"
      stroke={fill}
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.67"
    />
  </svg>
);
const pending = (fill) => (
  <svg
    fill="none"
    height="56"
    viewBox="0 0 56 56"
    width="56"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m4 28c0-13.2548 10.7452-24 24-24s24 10.7452 24 24-10.7452 24-24 24-24-10.7452-24-24z"
      fill="#e0eaff"
    />
    <path
      d="m28 22v6l4 2m6-2c0 5.5228-4.4772 10-10 10s-10-4.4772-10-10 4.4772-10 10-10 10 4.4772 10 10z"
      stroke="#444ce7"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
    />
    <path
      d="m28 48c-11.0457 0-20-8.9543-20-20h-8c0 15.464 12.536 28 28 28zm20-20c0 11.0457-8.9543 20-20 20v8c15.464 0 28-12.536 28-28zm-20-20c11.0457 0 20 8.9543 20 20h8c0-15.464-12.536-28-28-28zm0-8c-15.464 0-28 12.536-28 28h8c0-11.0457 8.9543-20 20-20z"
      fill="#eef4ff"
    />
  </svg>
);
const fail = () => (
  <svg
    fill="none"
    height="56"
    viewBox="0 0 56 56"
    width="56"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m4 28c0-13.2548 10.7452-24 24-24s24 10.7452 24 24-10.7452 24-24 24-24-10.7452-24-24z"
      fill="#fee4e2"
    />
    <path
      d="m31 25-6 6m0-6 6 6m7-3c0 5.5228-4.4772 10-10 10s-10-4.4772-10-10 4.4772-10 10-10 10 4.4772 10 10z"
      stroke="#d92d20"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
    />
    <path
      d="m28 48c-11.0457 0-20-8.9543-20-20h-8c0 15.464 12.536 28 28 28zm20-20c0 11.0457-8.9543 20-20 20v8c15.464 0 28-12.536 28-28zm-20-20c11.0457 0 20 8.9543 20 20h8c0-15.464-12.536-28-28-28zm0-8c-15.464 0-28 12.536-28 28h8c0-11.0457 8.9543-20 20-20z"
      fill="#fef3f2"
    />
  </svg>
);
const success = () => (
  <svg
    fill="none"
    height="56"
    viewBox="0 0 56 56"
    width="56"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m4 28c0-13.2548 10.7452-24 24-24s24 10.7452 24 24-10.7452 24-24 24-24-10.7452-24-24z"
      fill="#d1fadf"
    />
    <path
      d="m38 27.0801v.92c-.0012 2.1564-.6995 4.2547-1.9907 5.9818-1.2911 1.7272-3.106 2.9907-5.1739 3.6021-2.068.6114-4.2781.538-6.3009-.2093s-3.7498-2.1285-4.9235-3.9375c-1.1737-1.8091-1.7312-3.949-1.5893-6.1008.1419-2.1517.9755-4.2 2.3766-5.8392 1.4011-1.6393 3.2945-2.7817 5.3979-3.2569 2.1034-.4753 4.3041-.2579 6.2738.6198m5.93 1.14-10 10.01-3-3"
      stroke="#039855"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
    />
    <path
      d="m28 48c-11.0457 0-20-8.9543-20-20h-8c0 15.464 12.536 28 28 28zm20-20c0 11.0457-8.9543 20-20 20v8c15.464 0 28-12.536 28-28zm-20-20c11.0457 0 20 8.9543 20 20h8c0-15.464-12.536-28-28-28zm0-8c-15.464 0-28 12.536-28 28h8c0-11.0457 8.9543-20 20-20z"
      fill="#ecfdf3"
    />
  </svg>
);
const done = () => (
  <svg
    fill="none"
    height="16"
    viewBox="0 0 16 16"
    width="16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m0 8c0-4.41828 3.58172-8 8-8 4.4183 0 8 3.58172 8 8 0 4.4183-3.5817 8-8 8-4.41828 0-8-3.5817-8-8z"
      fill="#fff"
    />
    <path
      d="m4 8.5 2.5 2.5 5.5-5.5"
      stroke="#1c8b4b"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
    />
  </svg>
);
const bankCard = () => (
  <svg
    enable-background="new 0 0 352.069 352.069"
    viewBox="0 0 352.069 352.069"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="m337.102 56.035h-322.134c-8.254 0-14.968 6.714-14.968 14.966v39.147 61.6 109.32c0 8.252 6.714 14.966 14.968 14.966h322.134c8.253 0 14.968-6.714 14.968-14.966v-109.32-61.6-39.147c-.001-8.252-6.715-14.966-14.968-14.966zm-280.567 195.728h-15v-47h15zm30 0h-15v-47h15zm30 0h-15v-47h15zm162.242 7.234c-5.521 0-10.826-1.651-15.315-4.714-4.489 3.062-9.795 4.714-15.316 4.714-15.02 0-27.239-12.22-27.239-27.241s12.219-27.241 27.239-27.241c5.521 0 10.827 1.651 15.316 4.714 4.489-3.063 9.794-4.714 15.315-4.714 15.021 0 27.242 12.22 27.242 27.241.001 15.02-12.22 27.241-27.242 27.241zm63.292-97.248h-332.069v-41.6h332.069z" />
  </svg>
);

const help = () => (
  <svg
    fill="none"
    height="16"
    viewBox="0 0 17 16"
    width="17"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m6.56065 5.99992c.15674-.44556.4661-.82126.8733-1.06058.40721-.23932.88596-.3268 1.35149-.24695.46552.07985.88776.32188 1.19193.68321.30413.36134.47063.81867.46993 1.29099 0 1.33333-1.99998 2-1.99998 2m.05333 2.66671h.00667m6.65998-3.33338c0 3.68188-2.9847 6.66668-6.66665 6.66668-3.6819 0-6.66667-2.9848-6.66667-6.66668 0-3.6819 2.98477-6.66667 6.66667-6.66667 3.68195 0 6.66665 2.98477 6.66665 6.66667z"
      stroke="#667085"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.33333"
    />
  </svg>
);

const metamask = () => (
  <svg
    fill="none"
    height="23"
    viewBox="0 0 25 23"
    width="25"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M23.3133 0.799805L13.937 7.73677L15.6804 3.64812L23.3133 0.799805Z" fill="#E17726"/>
    <path d="M1.6875 0.799805L10.9793 7.80204L9.3195 3.64812L1.6875 0.799805Z" fill="#E27625"/>
    <path d="M19.937 16.8848L17.4429 20.696L22.7843 22.1667L24.3146 16.9683L19.937 16.8848Z" fill="#E27625"/>
    <path d="M0.694824 16.9683L2.21546 22.1667L7.54826 20.696L5.06282 16.8848L0.694824 16.9683Z" fill="#E27625"/>
    <path d="M7.26054 10.4466L5.77734 12.6853L11.0631 12.9263L10.8865 7.2373L7.26054 10.4466Z" fill="#E27625"/>
    <path d="M17.7396 10.4465L14.058 7.17285L13.937 12.9261L19.2228 12.6852L17.7396 10.4465Z" fill="#E27625"/>
    <path d="M7.54834 20.6961L10.7471 19.1515L7.99282 17.0059L7.54834 20.6961Z" fill="#E27625"/>
    <path d="M14.2529 19.1515L17.443 20.6961L17.0072 17.0059L14.2529 19.1515Z" fill="#E27625"/>
    <path d="M17.443 20.696L14.2529 19.1514L14.5121 21.223L14.4843 22.1024L17.443 20.696Z" fill="#D5BFB2"/>
    <path d="M7.54834 20.696L10.5157 22.1024L10.4975 21.223L10.7471 19.1514L7.54834 20.696Z" fill="#D5BFB2"/>
    <path d="M10.5714 15.6368L7.91895 14.8592L9.79191 13.999L10.5714 15.6368Z" fill="#233447"/>
    <path d="M14.4287 15.6368L15.2082 13.999L17.0908 14.8592L14.4287 15.6368Z" fill="#233447"/>
    <path d="M7.54843 20.696L8.01211 16.8848L5.06299 16.9683L7.54843 20.696Z" fill="#CC6228"/>
    <path d="M16.9878 16.8848L17.4428 20.696L19.9369 16.9683L16.9878 16.8848Z" fill="#CC6228"/>
    <path d="M19.2228 12.6855L13.937 12.9265L14.4285 15.6366L15.2071 13.9988L17.0906 14.859L19.2228 12.6855Z" fill="#CC6228"/>
    <path d="M7.9191 14.859L9.79206 13.9988L10.5716 15.6366L11.0631 12.9265L5.77734 12.6855L7.9191 14.859Z" fill="#CC6228"/>
    <path d="M5.77734 12.6855L7.99302 17.0055L7.9191 14.859L5.77734 12.6855Z" fill="#E27525"/>
    <path d="M17.0908 14.859L17.0073 17.0055L19.223 12.6855L17.0908 14.859Z" fill="#E27525"/>
    <path d="M11.0628 12.9268L10.5713 15.6368L11.1924 18.8365L11.3316 14.6192L11.0628 12.9268Z" fill="#E27525"/>
    <path d="M13.9369 12.9268L13.6777 14.6096L13.8073 18.8365L14.4285 15.6368L13.9369 12.9268Z" fill="#E27525"/>
    <path d="M14.4287 15.637L13.8076 18.8367L14.2531 19.1515L17.0073 17.0059L17.0908 14.8594L14.4287 15.637Z" fill="#F5841F"/>
    <path d="M7.91895 14.8594L7.99287 17.0059L10.7471 19.1515L11.1925 18.8367L10.5714 15.637L7.91895 14.8594Z" fill="#F5841F"/>
    <path d="M14.4843 22.1027L14.5122 21.2233L14.2712 21.0198H10.7288L10.4975 21.2233L10.5157 22.1027L7.54834 20.6963L8.58706 21.5478L10.6914 22.9993H14.2991L16.413 21.5478L17.4431 20.6963L14.4843 22.1027Z" fill="#C0AC9D"/>
    <path d="M14.2531 19.1508L13.8076 18.8359H11.1926L10.7471 19.1508L10.4966 21.2225L10.7289 21.019H14.2713L14.5123 21.2225L14.2531 19.1508Z" fill="#161616"/>
    <path d="M23.7118 8.18988L24.5 4.36045L23.3124 0.799805L14.2529 7.50637L17.7397 10.4468L22.6635 11.8811L23.7492 10.6139L23.276 10.2712L24.0267 9.58668L23.4516 9.14317L24.2033 8.56908L23.7118 8.18988Z" fill="#763E1A"/>
    <path d="M0.5 4.36045L1.29776 8.18988L0.788 8.56908L1.54832 9.14317L0.97328 9.58668L1.724 10.2712L1.25072 10.6139L2.33648 11.8811L7.26032 10.4468L10.747 7.50637L1.68752 0.799805L0.5 4.36045Z" fill="#763E1A"/>
    <path d="M22.6636 11.8815L17.7398 10.4473L19.223 12.686L17.0073 17.006L19.9372 16.9685H24.3148L22.6636 11.8815Z" fill="#F5841F"/>
    <path d="M7.26026 10.4473L2.33642 11.8815L0.694824 16.9685H5.06282L7.99274 17.006L5.77706 12.686L7.26026 10.4473Z" fill="#F5841F"/>
    <path d="M13.9369 12.9268L14.2528 7.50572L15.6803 3.64844H9.31934L10.7469 7.50572L11.0627 12.9268L11.1827 14.628L11.1923 18.8366H13.8073L13.8169 14.628L13.9369 12.9268Z" fill="#F5841F"/>
</svg>
);

const tron = () => (
  <svg
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_508_59880)">
      <path d="M23.0811 7.23032C21.9561 6.19157 20.3998 4.60532 19.1323 3.48032L19.0573 3.42782C18.9325 3.32761 18.7918 3.24902 18.6411 3.19532C15.5848 2.62532 1.36105 -0.0334315 1.08355 0.000318475C1.0058 0.0112085 0.931473 0.0394005 0.866055 0.0828185L0.794805 0.139068C0.707071 0.228169 0.640436 0.33581 0.599805 0.454068L0.581055 0.502818V0.769068V0.810318C2.1823 5.26907 8.5048 19.8753 9.7498 23.3028C9.8248 23.5353 9.96731 23.9778 10.2336 24.0003H10.2936C10.4361 24.0003 11.0436 23.1978 11.0436 23.1978C11.0436 23.1978 21.9036 10.0278 23.0023 8.62532C23.1445 8.45256 23.2701 8.26673 23.3773 8.07032C23.4047 7.91664 23.3918 7.7585 23.3398 7.61129C23.2879 7.46408 23.1988 7.33283 23.0811 7.23032ZM13.8298 8.76407L18.4648 4.92032L21.1836 7.42532L13.8298 8.76407ZM12.0298 8.51282L4.0498 1.97282L16.9611 4.35407L12.0298 8.51282ZM12.7498 10.2266L20.9173 8.91032L11.5798 20.1603L12.7498 10.2266ZM2.96605 2.62532L11.3623 9.75032L10.1473 20.1678L2.96605 2.62532Z" fill="#FF060A"/>
    </g>
    <defs>
      <clipPath id="clip0_508_59880">
        <rect width="24" height="24" fill="white"/>
      </clipPath>
    </defs>
  </svg>
);

export const svgIcons = {
  bankCard,
  help,
  fail,
  pending,
  success,
  btc,
  tether,
  eth,
  lock,
  info,
  left,
  change,
  copy,
  done,
  metamask,
  tron,
};
